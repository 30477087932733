.message {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  max-width: 90%;

  &--user-message {
    align-self: flex-end;
    flex-direction: row-reverse;
  }

  &__rtk-icon {
    width: 30px;
    height: 30px;
    margin-right: 12px;
    align-self: flex-start;
  }

  &__text {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 3px;
    flex-grow: 1;
    font-size: 14px;
    line-height: 16px;
    background-color: #A472DE;
    color: #ffffff;
    word-break: break-all;

    &--user-message {
      background-color: #F8F2DC;
      color: #000000;
    }
  }

  &__date {
    margin-left: 2px;
    font-size: 12px;
    line-height: 10px;
    color: #606060;
    align-self: flex-end;

    &--user-message {
      margin-left: 0;
      margin-right: 5px;
    }
  }
}