.offlineProfit {
  h4 {
    margin: 0;
    font-size: 16px;
  }

  &__mainHeader {
    margin-bottom: 9px !important;
  }

  p {
    font-size: 14px;
    margin-bottom: 9px;
  }

  &__income {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    img {
      height: 14px;
      margin: 0 5px;
    }
  }

  &__doubleIncome {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      img {
        margin-right: 5px;
        height: 14px;
      }
    }
  }

  &__assistant-link {
    color: #7700FF;
    cursor: pointer;
  }
}
