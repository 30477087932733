.questInfo {
  h4 {
    font-size: 16px;
    margin: 0 0 7px 0;
  }

  p {
    font-size: 14px;
    margin: 0;

    white-space: pre-line;
  }

  b {
    font-size: 14px;
  }

  &__body {
    position: relative;
  }

  &__startup {
    &__button {
      margin-top: 10px;
    }
  }

  &__serviceOffer{
    p:nth-child(2){
      margin: 5px 0;
    }
    button{
      margin-top: 7px;
    }
  }

  &__addClients {
    white-space: nowrap;
    color: black;
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      b {
        width: 100%;
      }

      p {
        width: 100%;
        margin: 0;
        text-align: center;
      }
    }

    &__count {
      &--minus {
        color: brown;
      }
      &--plus {
        color: #77b255;
      }
    }
  }

  &__manager {
    display: flex;
    flex-direction: row;

    align-items: center;

    & > div:nth-child(2) {
      width: 100%;
      white-space: normal;

      h4,
      p {
        margin: 3px 0;
        padding-left: 10px;
      }
      h4 {
        font-size: 14px;
      }
      p {
        font-size: 12px;
      }
    }
  }

  &__buttons {
    h4 {
      margin: 3px 0;
    }

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    button {
      img {
        width: 20px;
      }
    }
  }
}

.manager {
  position: relative;
  min-width: 80px;
  height: 75px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  & > div {
    span {
      font-size: 10px;
    }
  }

  & > img {
    position: absolute;
    margin-top: 3px;
  }

  p {
    width: 100%;
    font-weight: normal;
    font-size: 12px;
    margin: 5px 0;
    text-align: center;
  }
}
