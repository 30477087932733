.selectbox {
  width: 100%;
  height: 20px;
  border: 0 !important;
  background-color: white;
  outline: none !important;
  border-radius: 3px;

  input {
    &::placeholder {
      font-size: 14px;
      color: black !important;
    }
  }

  & > div:nth-child(3n + 3) {
    top: 38px !important;
    border: 0;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }

  span {
    display: flex;
    color: black !important;
    align-items: center;

    margin: 3px 10px;

    &[aria-selected="true"] {
      background-color: #ebebeb;
      border-radius: 3px;
    }
  }
}
