p {
  margin: 0;
}

.partnership {

  &__banner {
    width: 100%;
    height: 105px;
    border-radius: 10px;
    overflow: hidden;
  }

  &__banner-image {
    width: 100%;
    height: 105px;
    object-fit: cover;
  }

  h5 {
    font-size: 14px;
    margin: 0 0 9px 0;
  }

  &__description {
    border: 0 !important;
    h5{
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  }

  &__text-before-prizes-list {
    margin-bottom: 6px;
  }

  &__awards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    img{
      max-width: 100%;
    }
   
  }

  &__reward-label {
    font-size: 16px;
    font-weight: bold;
    margin-right: 4px;
  }

  &__reward-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
  }

  &__reward-coins-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
  }

  &__reward-coins-icon {
    width: 20px;
  }

  &__reward-coins-icon-container {
    display: flex;
    align-items: center;
  }

  &__reward-coins-text {
    font-size: 16px;
  }

  &__bonus {
    display: flex;
    flex-direction: column;

    font-size: 14px;

    button {
      min-width: auto;
      width: 100%;
      max-width: 100%;

      font-size: 12px;
    }

    &__progressbar {
      margin: auto;
      margin-left: 10px;
      div {
        width: 106px;
      }
    }
  }

  &__text-after-prizes-list {
    margin-bottom: 0;
  }

  &__link {
    display: none;
  }
}

.selector {
  & > div > div {
    height: 49px;
    img {
      max-width: 30px;
    }
  }
}
