@import "../../../index.scss";

.scroll-arrow {
  left: 0;
  right: 0;
  bottom: -40px;
  position: absolute;
  height: 58px;
  border-radius: 100%;
  animation: down 1.5s infinite;
  // margin: 18px auto 42px auto;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(-45deg) translate(-50%, -50%);
  }
}