@import "./styles/base.scss";
@import "./styles/colors.scss";
@import "./styles/breakpoints.scss";
@import "./styles/fonts.scss";

html, body{
  overscroll-behavior-y: contain;
  overflow: hidden;
  position: fixed;

  @extend .disabled-highlight;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-overflow-scrolling: touch;
  font-family: "rtk-basis";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

canvas {
  display: block;
}

// disable highlight
img {
  @extend .disabled-highlight;
}

.modal {
  @extend .full-screen-absolute-container;
  display: flex;
  position: fixed;

  z-index: 9;

  width: 100%;
  height: 100%;

  &.shadowed {
    background-color: rgba(0, 0, 0, 0.5);

    & .modal__body {
      box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5);
    }
  }
}

a {
  text-decoration: none;
  color: #7700FF;
}

div::-webkit-scrollbar {
  width: 0;
}

.modal__body {
  display: flex;
  flex-direction: column;

  position: relative;
  background: #ffffff;
  min-height: fit-content;
  max-height: 477px;
  width: 298px;
  margin: auto;
  padding: 0 11px 11px;

  box-sizing: border-box;
  border-radius: 20px;

  & > div:nth-child(2) {
    border-radius: 10px;
    margin-top: 5px;
    
    max-height: 348px;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}

.shadow {
  position: absolute;

  
  background-color: rgba(0, 0, 0, 0.5);  
  // box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

#root {
  overflow-x: hidden;
}

.input {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #DADCE4;
  border-radius: 3px;
  padding-left: 10px;
  outline: none;
  transition: border 300ms ease-out;
  font-size: 14px;

  &--error {
    border-color: red;
  }
}

.input:focus {
  border-color: $color-green;
}

.grecaptcha-badge { 
  display: none !important;
}

.tutorial-canvas {
  @extend .full-screen-absolute-container;
  top: 52px;
}

.highlighted {
  z-index: 2;
  position: relative;

  &::before {
    z-index: 3;
    content: '';
    @extend .full-screen-absolute-container;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  * {
    position: relative;
    z-index: 4;
  }
}

.arrow {
  z-index: 3;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url("./images/arrow.png");
  width: 38px;
  height: 47px;

  // border-left: 2px solid white;
  // border-bottom: 2px solid white;
}

.with-arrow {

  //will clear later
  &.bottom {
    @keyframes arrowdown {
      0% {
      transform: translate(-50%, -50%);
      }
      20% {
      transform: translate(-50%, -20%);
      }
      40% {
      transform: translate(-50%, -50%);
      }
    }
    &::after {
      @extend .arrow;
      top: -150%;
      animation: arrowdown 1.5s infinite;
      transform: translate(-50%, -50%);
    }
  }
  
  &.top {
    @keyframes arrowtop {
      0% {
      transform: translate(-50%, -50%) rotate(180deg);
      }
      20% {
      transform: translate(-50%, 0) rotate(180deg);
      }
      40% {
      transform: translate(-50%, -50%) rotate(180deg);
      }
    }
    &::after {
      @extend .arrow;
      top: unset;
      bottom: -80px;
      animation: arrowtop 1.5s infinite;
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  &.right {
    @keyframes arrowright {
      0% {
      transform: translate(-50%, -50%) rotate(90deg);
      }
      20% {
      transform: translate(-20%, -50%) rotate(90deg);
      }
      40% {
      transform: translate(-50%, -50%) rotate(90deg);
      }
    }
    &::after {
      @extend .arrow;
      left: unset;
      right: -75%;
      animation: arrowright 1.5s infinite;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &.left {
    @keyframes arrowleft {
      0% {
      transform: translate(-50%, -50%) rotate(-90deg);
      }
      20% {
      transform: translate(-20%, -50%) rotate(-90deg);
      }
      40% {
      transform: translate(-50%, -50%) rotate(-90deg);
      }
    }
    &::after {
      @extend .arrow;
      left: -50%;
      animation: arrowleft 1.5s infinite;
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }
}

.scroll-container {
  max-height: 300px;
  overflow: scroll;
  -webkit-overflow-scrolling: auto !important;

  border-radius: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-height: $mobile-height-breakpoint) {
    max-height: 180px;
  }
}

.wrap {
  flex-wrap: wrap;
}

@keyframes down {
  0% {
  transform: translateY(0);
  }
  20% {
  transform: translateY(15px);
  }
  40% {
  transform: translateY(0);
  }
}

