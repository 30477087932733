@import "../../../../styles/colors.scss";

.leaderboard {
  height: fit-content;
  width: 100%;

  h4 {
    line-height: 1;
    margin: 0;
    font-weight: bold;
  }

  p {
    font-size: 14px;
    margin: 4px 0px;
  }

  &__list {
    display: flex;
    flex-flow: column;

    & > :not(:last-child) {
      margin-bottom: 3px;
    }
  }
}

.line {
  display: flex;
  flex-flow: row;
  align-items: baseline;
  width: 100%;
  height: fit-content;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 8px 12px 8px 8px;
  border-radius: 3px;
  font-size: 14px;

  &__star {
    align-self: flex-start;
    margin-left: 5px;
  }

  &__user-score {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    margin-left: 5px;
    word-break: break-word;
  }

  &--highlight {
    position: relative;
    margin: 17px 0px 20px !important;
    background: linear-gradient(180deg, #65E3FF 0%, #28C4E7 95%);

    * {
      color: #FFFFFF !important;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    }

    &:before {
      content: "";
      width: 100%;
      position: absolute;
      top: -10px;
      left: 0;
      border: 1px dashed #B9BEC2;
      box-sizing: border-box;
    }

    &:after {
      content: "";
      width: 100%;
      position: absolute;
      bottom: -10px;
      left: 0;
      border: 1px dashed #B9BEC2;
      box-sizing: border-box;
    }
  }
}

.score {
  display: flex;
  flex-flow: row;
  color: $color-green;

  img {
    margin-left: 4px;
    filter: drop-shadow(0px 0px 3px rgba(247, 54, 120, 0.4));
  }
}
