@import '../../../styles/colors.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 140px;
  min-width: 120px;
  height: 30px;

  outline: none;
  border: none;

  background: $gradient-green-button;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  font-style: normal;
  font-weight: normal !important;
  font-size: 12px;
  color: #ffffff;

  cursor: pointer !important;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    $gradient-green-button;
  }

  &--orange {
    background: linear-gradient(180deg, $color-red 0%, #ed3e02 100%);

    &:hover {
      background: linear-gradient(180deg, $color-red 0%, #ed3e02 100%);
    }
  }

  &--red {
    background: linear-gradient(180deg, #FF4F12 0%, #ED3E02 100%);

    &:hover {
      background: linear-gradient(180deg, #FF4F12 0%, #ED3E02 100%);
    }
  }

  &--bordered {
    word-break: normal;
    white-space: nowrap;
    word-break: normal;
    color: $color-green;
    border: 0.5px solid $color-green;

    background: $gradient-disabled;

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      $gradient-disabled;
    }

    &--orange {
      color: $color-red;
      border: 0.5px solid $color-red;
    }
  }

  &--disabled {
    cursor: default !important;
    background: $gradient-disabled-hover;
    &:hover {
      background: $gradient-disabled-hover;
    }
  }
}
