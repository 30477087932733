.skipTimer {
  h4 {
    margin: 0 0 9px 0;
  }
}

.price {
  width: 16px;
  height: 16px;

  margin-bottom: 1px;
  margin-left: 2px;
}
