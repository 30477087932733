.send-message-button {
  width: 32px;
  height: 30px !important;  //TODO: отрефакторить вёрстку промо сайта 
                            // (из - за того, что стили присваются элементу внутри класса промо, одному классу не хватает каскадности и он перекрывается)
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: linear-gradient(180deg, #FF4F12 0%, #ED3E02 100%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
  
  &__arrow {
    width: 12px;
    height: 14px;
  }
}