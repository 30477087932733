@import "../../../../../../styles/colors.scss";

.assistant {
  &__body {
    display: flex;
    flex-direction: row;
    padding: 10px 0px 10px 10px;
  }

  &__avatar {
    flex: 0 0 80px;
    margin-right: 10px;
  }

  &__info {
    flex-grow: 1;
  }

  &__header {
    margin: 0px 0px 5px 0px;
    font-size: 14px;
    line-height: 16px;
  }

  &__description {
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    white-space: pre-line;
  }

  &__footer {
    margin-top: 1px !important;
  }

  &__hire-assistant {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__offer {
    line-height: 16px;
    font-size: 16px;
    font-weight: bold;
    margin-right: 27px;
  }

  &__pay-button {
    min-width: 0;
    flex: 0 0 80px;
  }

  &__crystal-icon {
    height: 14px;
    margin-right: 2px;
  }

  &__hired {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__left-side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__contract-header {
    font-size: 14px;
    line-height: 16px;
    font-size: 700;
    color: $color-green;
  }

  &__contract-label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  &__reamining-time {
    flex: 0 0 106px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }

  &__timer-container {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__timer {
    flex: 0 0 106px;
  }
}