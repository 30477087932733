.support-request-item {
  background-color: #E8EEF3;
  border-radius: 10px;

  &__head {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  &__expand-arrow-button {
    margin-left: 16px;
    margin-right: 16px;
  }

  &__expanded-content-container {
    width: 100%;
    height: 0;
    transition: height 0.5s;
    display: flex;
    flex-direction: column;
    align-self: center;
    overflow: hidden;

    &--open {
      height: 300px;
    }
  }

  &__messages {
    background-color: #fff;
    flex-grow: 1;
    width: calc(100% - 2px - 20px);
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &__input-container {
    display: flex;
    padding: 10px;
  }

  &__input {
    width: calc(100% - 32px - 11px);
    margin-right: 11px;
  }

  &__send-button {
    align-self: flex-end;
  }
}