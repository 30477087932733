.crash {
 
  &__description {
    h4 {
      margin: 0 0 5px 0;
      font-size: 16px;
    }

    color: #b52e00;

    p {
      font-size: 14px;
      margin: 0;
    }

    &__filling{
        & > div{
            height: 24px;

        }
    }
  }

  &__fix {
    h4 {
      margin: 0 0 5px 0;
      font-size: 16px;
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      button{
        img{
          width: 20px;
        }
        span{
          font-size: 12px;
        }
        font-size: 12px;
      }
    }
  }

}
