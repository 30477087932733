.progressbar {
  cursor: default !important;

  box-sizing: border-box;
  padding: 2px !important;

  background: #ffffff;
  border-radius: 3px;

  position: relative;

  &-line {
    margin: 0 !important;
    height: 20px;
    border-radius: 2px;

    position: unset !important;

    background: linear-gradient(90deg, #a5d813 43%, #e6ea16 49%, #ea6f16 59%)
      fixed;

    &--blue {
      background: linear-gradient(180deg, #65e3ff 0%, #28c4e7 95%);
    }
    &--purple {
      background: linear-gradient(180deg, #cab5f9 13.33%, #b096eb 100%);
    }
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 3px;
    height: 45%;
    margin: auto;
  }

  &__indicator {
    display: flex;
    position: absolute !important;

    align-items: center;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    margin: auto;

    font-style: normal;
    font-weight: bold;
    font-size: 14px;

    &--small {
      font-size: 10px !important;
    }

    embed {
      margin-right: 5px;
    }
  }

  &--pointer{
    cursor: pointer !important;

  }
}
