.rules {
  line-height: 16px;

  @media (max-width: 767px) {
    line-height: 14px;
  }

  h1 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 160%;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  h2 {
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    font-weight: bold;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  h3 {
    font-size: 18px;
    line-height: 28.8px;
    margin: 0;
    font-weight: bold;

    @media (max-width: 767px) {
      line-height: 27px;
    }
  }

  & > div {
    display: grid;
  }

  & > :first-child {
    row-gap: 14px;
  }

  & > :not(:first-child) {
    row-gap: 10px;
    margin-top: 40px;
  }

  &__contentsTable {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;

    & > div {
      display: grid;
      row-gap: 16px;
    }
  }

  &__images {
    display: flex;
    flex-flow: column;
    position: absolute;
    width: calc(624/1280 * 100vw);
    top: 2120px;
    right: 26px;
    align-items: flex-start;
    pointer-events: none;

    @media (max-width: 1279px) {
      display: none;
    }

    img{
      image-rendering: auto;
      image-rendering: crisp-edges;
      image-rendering: pixelated;

      /* Safari seems to support, but seems deprecated and does the same thing as the others. */
      image-rendering: -webkit-optimize-contrast;
    }

    & > :not(:first-child) {
      filter: drop-shadow(0px 13.2904px 33.226px rgba(0, 0, 0, 0.1));
    }
  }

  &__rule1-img {
    width: 477px;
    height: 371px;
    margin-bottom: 478px;
  }

  &__rule2-img {
    width: 347px;
    height: 617px;
    margin-bottom: 345px;
  }

  &__rule3-img {
    width: 555px;
    height: 561px;
    align-self: flex-start;
    margin-bottom: 31px;
  }

  &__rule4-img {
    width: 403px;
    height: 544px;
    margin-bottom: 175px;
  }

  &__rule5-img {
    width: 623px;
    height: 643px;
    margin-bottom: 35px;
  }

  &__rule6-img {
    width: 408px;
    height: 377px;
    margin-bottom: 175px;
  }

  &__rule7-img {
    width: 438px;
    height: 557px;
    margin-bottom: 50px;
  }

  &__rule8-img {
    width: 219px;
    height: 448px;
    margin-bottom: 520px;
    align-self: flex-start;
  }

  &__rule9-img {
    width: 220px;
    height: 370px;
    margin-bottom: 310px;
  }

  &__rule10-img {
    width: 344px;
    height: 497px;
    margin-bottom: 20px;
  }

  &__rule11-img {
    width: 490px;
    height: 535px;
  }

  ul {
    margin: 0;
    font-size: 16px;
    line-height: 25.6px;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 22.4px;
    }
  }

  &__dotList {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    max-width: calc(100% - 24px);
    line-height: 25.6px;
    list-style: url("../../images/svg/list.svg");
    padding-left: 24px;

    & > :not(:last-child) {
      margin-bottom: 10px;
    }

    li {
      min-height: 28px;

      span{
        white-space: pre-wrap;
      }
    }
  }

  &__prizesBlock {
    max-width: none;
    width: 1015px;

    @media (max-width: 1279px) {
      width: 100%;
      max-width: 100%;
    }

    &__logoList {
      display: grid;
      grid-template-columns: repeat(5, 120px);
      column-gap: 46px;
      row-gap: 18px;
      align-items: center;
      justify-items: center;
  
      @media (max-width: 1279px) {
        width: inherit;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 38px;
        row-gap: 40px;
        max-width: none;

        img {
          max-height: 28px;
          max-width: 100%;
        }
      }

      img {
        max-height: 28px;
        max-width: 100%;
      }
    }

    ul {
      max-width: unset;
      max-height: 772px;
      margin: 0 0 8px;


      @media (max-width: 1279px) {
        max-height: 480px;
        width: auto;
        overflow: hidden;
      }

      li {
        width: 474px;

        @media (max-width: 1279px) {
          max-width: 100%;
          width: 100%;
          margin-right: 24px;
        }
      }
    }
  }

  &__pdf {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &::before {
      content: "";
      width: 24px;
      height: 30px;
      background-image: url("../../images/promo/PDF.svg");
      margin-right: 8px;
    }
  }

  &__iconList {
    padding: 0;
    list-style: none;

    & > :not(:last-child) {
      margin-bottom: 20px;
    }

    li {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;

      p {
        margin: 0;
      }
      
      & > :first-child {
        margin-right: 20px;
      }
    }

    svg {
      width: 28px;
    }
  }

  &__booster {
    height: 45px;
  }

  .normalSizeIconContainer {
    font-size: 28px;
  }
}
