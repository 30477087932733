.selector {
  & > div > div {
    padding: 10px;

    span {
      margin: 0;
    }
  }
}

.fastbuy {
  button {
    padding: 3px;
    font-size: 12px;
    img {
      margin-right: 5px;
    }
  }

  &__score {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background: #fff;

    h4 {
      text-align: center;
      padding: 10px;
      font-weight: bold;
      font-size: 14px;
      margin: 0;
    }

    img {
      position: absolute;
      margin: 9px;
      height: 18px;
    }
  }
}
