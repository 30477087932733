.confirm{
    h4{
        font-size: 16px;
        margin: 0 0 12px 0;
    }
    p{
        font-size: 14px;
        margin: 0 0 15px 0;
    }
    button{
        width: 100px;
        height: 30px;
    }
}