@import "../../../styles/colors.scss";
@import "../../../styles/base.scss";
@import "../../../styles/breakpoints.scss";

.modal {
  position: absolute;

  display: flex;
  flex-direction: column;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 300px;
  max-height: 477px;

  height: fit-content;
  margin: auto;

  border-radius: 20px;
  background-color: #fff;

  box-sizing: border-box;

  @media (max-height: $mobile-height-breakpoint) {
    max-height: 320px;
  }

  &__closeButton {
    position: absolute;
    top: -8px;
    right: 18px;
    z-index: 2;
  }

  &__header {
    background: linear-gradient(180deg, #ded2f9 13.33%, #9f82e0 100%);
    border-radius: 20px 20px 0 0;

    text-align: center;

    font-size: 16px;
    color: #ffffff;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    padding: 16px 0 7px 0;
    font-weight: bold;

    &__headerIcon {
      position: absolute;

      width: 30px;
      height: 30px;

      top: 0px;
      bottom: 0;
      right: 0;
      left: 0;

      margin: 0 auto;
      margin-top: -13px;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__body {
    position: relative;
    box-sizing: border-box;
    margin: 11px;
    border-radius: 10px;

    max-height: 300px;
  }

  &__footer {
    // z-index: 1;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // width: 300px;
    background: linear-gradient(180deg, #ded2f9 13.33%, #9f82e0 100%);
    border-radius: 20px;

    padding: 11px;
    // box-sizing: border-box;
  }
}

.container {
  padding: 11px 11px 0;
  max-height: 300px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
