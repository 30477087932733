@import "../../../../styles/breakpoints.scss";

.prize {
  &__header {
    margin: 0;
    white-space: pre-wrap;
  }

  &__prizeText {
    font-size: 14px;
    font-weight: 700;
    margin: 4px 0 24px;

    @media screen and (max-height: $mobile-height-breakpoint) {
      margin-bottom: 8px;
    }
  }

  &__bottomText {
    margin: 0 0 16px;
    font-size: 12px;
  }

  button {
    width: 100%;
    max-width: unset;
    font-size: 12px;
  }
}
