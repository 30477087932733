.message-input {
  min-height: 30px;
  background-color: #fff;
  font-size: 14px;
  color: #000;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 16px;
  resize: none;
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-y: auto;

  &:active {
    border: none;
  }

  &:focus {
    outline: none;
    border: none;
  }
}