@import "../../../../styles/colors.scss";

.giftToday {
  display: flex;

  justify-content: space-between;
  align-items: center;

  &__body {
    padding-bottom: 11px;
    box-sizing: border-box;
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    margin: 8px 0px;
  }

  & > div {
    &:first-child {
      h5 {
        margin: 0 0 13px 0;
        font-size: 14px;
        line-height: 18px;
      }
    }
    &:last-child {
      width: 113px;
      height: 81px;
      background: radial-gradient(
        48.82% 60.34% at 29.62% 39.66%,
        #ffeb81 0%,
        #ffc839 68.23%
      );

      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }
  }
}

.range{
    background-color: transparent;
    div{
        &:first-child{
            background-color: white;

        }
        &:nth-child(2){
            background-color: #A68CE3;
        }

    }
}

.awardIsReceived{
  margin-top:10px;
  color: $color-green;
  font-size: 12px;
  font-weight: bold;
}