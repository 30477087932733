@import "../../../styles/colors.scss";

.feedbackForm {
  display: grid;
  row-gap: 10px;
  background: #E8EEF3;
  border-radius: 9px;
  padding: 18px;

  label {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 84px;
    border-radius: 3px;
    border: 1px dashed #9B9B9B;
    background: transparent;
    position: relative;
  
    input {
      display: none;
    }
  }

  label:after {
    content: "+ Добавить файл";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: 14px;
    color: #979799;
  }

  &__textarea {
    appearance: none;
    width: 100%;
    height: 115px;
    box-sizing: border-box;
    border: 1px solid rgb(218, 220, 228);
    border-radius: 3px;
    padding: 8px 10px;
    outline: none;
    margin: 0px;
    resize: none;
    transition: border 300ms ease-out;

    &--error {
      border-color: red;
    }
  }

  &__textarea:focus {
    border-color: $color-green;
  }

  &__inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    row-gap: 10px;
    column-gap: 15px;
    align-items: center;

    @media (max-width: 1089px) {
      grid-template-columns: none;
      grid-auto-flow: row;
    }
  }

  @media (max-width: 1089px) {
    button {
      width: 100%;
      max-width: 100%;
    }
  }

  button {
    max-width: none;
  }

  &__files {
    display: grid;
    grid-auto-flow: row;
    width: 100%;
    row-gap: 5px;

    &__minus {
      width: 15px;
      height: 3px;
      background-color: #9B9B9B;
      border-radius: 16px;
      margin: 0 8px;
      cursor: pointer;
    }

    &__file {
      display: flex;
      height: 30px;
      border: 1px solid #9B9B9B;
      background: transparent;
      border-radius: 3px;
      align-items: center;
      color: #979799;
      font-size: 14px;
    }
  }

  &__faq {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    margin-bottom: 24px;

    &::before {
      content: "";
      width: 24px;
      height: 30px;
      background-image: url("../../../images/promo/faq.svg");
      margin-right: 8px;
    }
  }
}

.policy {
  width: 100%;    
  font-size: 12px;
  line-height: 14px;
  color: #979799;
  margin: 0;
}

.select {
  width: 100% !important;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #DADCE4 !important;
  border-radius: 3px !important;
  padding-left: 10px !important;
  outline: none;
  transition: border 300ms ease-out;
  background-color: #ffffff;
  font-size: 14px;

  &--error {
    border-color: red !important;

    &:focus-within {
      border-color: red !important;
    }

    &:hover {
      border-color: red !important;
    }
  }

  &:hover {
    border-color: #DADCE4 !important;
  }

  &:focus-within {
    border-color: $color-green !important;
  }

  input {
    margin-left: 0;
    &::placeholder {
      font-size: 14px;
      color: #9B9B9B !important;
    }
  }

  & > :first-child {
    display: flex;
    height: 40px;
    width: 100%;
    font-size: 14px;
  }
  

  & > div:nth-child(4n + 4) {
    top: 40px !important;
    border: 0;
    border-radius: 3px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    width: 100%;
  }

  span {
    display: flex;
    color: inherit;
    align-items: center;
    height: 40px;

    &[aria-selected="true"] {
      background-color: #DADCE4;
    }
  }
}