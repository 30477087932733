@import "../../styles/colors.scss";

.promo {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  width: 100vw;
  height: 100vh;
  font-size: 16px;
  line-height: 16px;
  overflow-y: scroll;
  user-select: text;

  h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 105%;
    margin: 0;

    @media (max-width: 1089px) {
      font-size: 30px;
    }
  }

  button {
    height: 40px;
    font-size: 12px;
    
    @media (max-width: 1089px) {
      min-width: fit-content;
    }
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 100px;
    margin-left: 128px;
    padding-bottom: 120px;
    max-width: 478px;
    min-height: fit-content;
    position: relative;

    @media (max-width: 1089px) {
      width: 90vw;
      margin: 0 auto;
    }

    h1 {
      margin: 14px 0px;
    }

    & > h2 {
      font-size: 16px;
      line-height: 105%;
      letter-spacing: -0.567966px;
    }  

    & > p {
      margin: 16px 0px;
    }

    h1 + p {
      margin: 0px;
    }

    & > img {
      width: fit-content;
    }

    & > embed {
      height: 95px;
      max-width: 90px;
    }

    &__block {
      width: 100%;
      background: #E8EEF3;
      border-radius: 9px;
      padding: 18px;
      box-sizing: border-box;
    }

    &__tabs {
      display: flex;
      flex-direction: row;
      width: 100%;
      min-height: fit-content;
      font-size: 16px;
      font-weight: bold;
  
      & > :first-child {
        margin-right: 50px;
      }
  
      & > div {
        cursor: pointer;
        width: fit-content;
  
        p {
          margin-bottom: 11px;
        }
  
        div {
          width: 100%;
          height: 4px;
          background: $color-green;
        }
      }
    }

    &__pdf {
      position: absolute;
      width: fit-content;
      top: 150px;
      right: -30px;
      color: $color-red;

      @media (max-width: 1089px) {   
        position: static;
        margin: 8px 0px;
      }  
    }
  }

  // TODO избавиться от этого класса тут. Перенести стили в SideBanner
  &__side-banner-img {
    width: 40vw;
    height: 615px;
    top: 120px;
    position: absolute;
    right: 0px;
    overflow: hidden;
    border-radius: 50px 0 0 50px;

    @media (max-width: 1089px) {
      display: none;
    }
  }
}

.promo-hero-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("../../images/promo/game-city-hero-tiny.jpg");
  background-size: cover;
  background-position: top;
  filter: blur(20px);

  &--bg-loaded {
    background-image: url("../../images/promo/game-city-hero.jpg");
    filter: none;
  } 
}
/*
.promoBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("../../images/promo/background-low.png");
  filter: blur(20px);
  background-size: 100vw auto;
  background-repeat: no-repeat;
  background-position: top;

  &--loaded {
    background-image: url("../../images/promo/background.png");
    filter: none;
  }

  @media (max-width: 1279px) {
    background-size: auto 90vh;
    background-position: top;
  }
}
*/
.rules {
  margin: 24px 0px 40px;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 22px;
  }

  li {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    list-style: none;

    p {
      margin: 0;
    }

    img {
      margin-right: 23px;
    }
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    grid-column-gap: 22px;
    column-gap: 22px;
    row-gap: 16px;
    align-items: center;

    img {
      max-height: 28px;
      max-width: 100%;
    }
  }
}
