.tender {
  
  h4 {
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    margin-bottom: 8px;
  }
  h5 {
    font-size: 16px;
    line-height: 16px;
    margin: 0;
  }
  P {
    line-height: 18px;
    margin: 0;
    font-size: 14px;
  }

  &__participate {
    display: flex;
    flex-direction: row;

    button {
      font-size: 12px;
      font-weight: bold;

      img{
          width: 20px;
          align-items: center;
      }
    }
  }
}
