.hud-button {
  position: relative;

  cursor: pointer;
  background: linear-gradient(180deg, #e2e2e2, #ffffff);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5);
  border-radius: 65px;
  // background-clip: padding-box;
  border: 0;
  padding: 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    background: linear-gradient(to bottom, #ffffff, #e2e2e2);
  }

  &--big {
    width: 54px;
    height: 54px;

    img {
      width: 43px;
      height: 43px;
    }
  }

  &--normal {
    width: 40px;
    height: 40px;

    img {
      width: 30px;
      height: 30px;
      margin: auto;
    }
  }

  &--small {
    width: 30px;
    height: 30px;

    span {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.5);
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  &--warning {
    background: linear-gradient(142.5deg, #f10e00 47.14%, #f15700 83.97%);
    box-shadow: 0px 0px 10px rgba(241, 22, 0, 0.5);

    width: 36px;
    height: 36px;
    
    img {
      position: absolute;
      
      width: 34px;
      height: 34px;

      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      margin: auto;
    }

    &:before {
      background: linear-gradient(142.5deg, #f15700 47.14%, #f10e00 83.97%);
    }
  }

  &__badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    width: 23px;
    height: 23px;

    top: -9px;
    left: 34px;

    background: linear-gradient(142.5deg, #f15700 47.14%, #f10e00 83.97%);
    box-shadow: 0px 0px 10px rgba(225, 0, 0, 0.5);
    border-radius: 65px;

    font-size: 11px;
    color: #ffffff;

    span{
      font-size: 11px;
      font-weight: bold;
    }
  }

  &:hover{
    &:before {
      background: linear-gradient(#e2e2e2, #ffffff);
    }
  }
}
