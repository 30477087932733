@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/colors.scss";

.selector {
  & > div > div {
    padding: 3px 0;

  }
  img {
    width: 35px;
  }
}

.store {

  h5, h2 {
    font-weight: bold;
  }

  &__balance {
    position: absolute;
    display: flex;
    flex-direction: row;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    justify-content: space-between;
    top: 0;
    font-size: 14px;
    font-weight: bold;

    @media screen and (min-width: $tablet-breakpoint) {
      display: none;
    }
  }

  button{
    font-size: 12px;
    font-weight: bold;
  }

  h5 {
    margin: 0;
    font-size: 16px;
    margin-bottom: 7px;
  }

  h2 {
    background: linear-gradient(180deg, #65e3ff 0%, #28c4e7 95%);
    border-radius: 3px;
    font-size: 14px;
    padding: 6px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    margin: 0;
    color: #ffffff;
    margin-bottom: 5px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  }

  &__currency {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
   
    button { 
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px;

      img{
        height: 12px;
        margin: 0 4px;
      }
    }
    
    & > div {
      position: relative;
      margin-bottom: 10px;
      width: 124px;

      & > {
        width: 100%;
      }

      &:nth-child(even) {
        padding-right: 0 !important;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(180deg, #65e3ff 0%, #28c4e7 95%);
      border-radius: 3px;
      height: 29px;
      h5 {
        color: white;
        margin: 0;
        font-weight: normal;
        font-size: 14px;

        text-align: center;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
      }
    }

    &_body {
      position: relative;
      background: radial-gradient(
        48.82% 60.34% at 29.62% 39.66%,
        #ffeb81 0%,
        #ffc839 68.23%
      );
      height: 76px;
    }
  }

  &__kit {
    h2 > span {
      margin-top: 5px !important;
    }

    & > div:first-child {
      h2 {
        position: relative;
        background: linear-gradient(180deg, #65e3ff 0%, #28c4e7 95%);
        border-radius: 3px;
        font-size: 14px;
        padding: 6px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        margin: 0;
        color: #ffffff;
        margin-bottom: 5px;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
      }
    }

    & > div:nth-child(2) {
      display: flex;
      flex-wrap: wrap;

      & > div:nth-child(5) {
        margin-right: 0 !important;
      }

      & > div {
        height: 45px;
        flex: 0 1 calc(20% - 5.6px);
      }
    }

    button {
      padding: 2px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      vertical-align: middle;

      width: 100%;
      max-width: 100%;
      min-height: 20px;
      height: fit-content;

      img {
        width: 15px;
      }
    }
    margin-bottom: 10px;

    &--notAvailable{
      h2{
        background: $gradient-disabled-hover !important;
      }

      & > div:nth-child(2) {
        & > div {
          & > div {
            background: $gradient-disabled-hover !important;

          }
        }
      }
    }
  }

  &__boosters {
    & > div:nth-child(2) {
      display: flex;
      flex-wrap: wrap;

      & > div:nth-child(5) {
        margin-right: 0 !important;
      }

      & > div {
        flex: 0 1 calc(100% / 3 - 5.6px);
      }
    }

    button{
      img{
        width: 14px;
        image-rendering: -webkit-optimize-contrast;
      }
    }
  }
}

.price-text {
  font-weight: bold;
}
