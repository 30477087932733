
@import "../../styles/breakpoints.scss";

.history {
  display: grid;
  row-gap: 3px;
}

.history-line {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  min-height: 30px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 0px 12px;
  border-radius: 3px;
  font-size: 14px;
  word-break: break-word;

  @media (max-width: $tablet-breakpoint) {
    grid-auto-flow: row;
    padding: 12px;
    row-gap: 10px;
  }

  &__date {
    width: 90px;
    color: #A0A0A0;

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    width: 90px;

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }
  }

  &__description {
    margin: 7px 0px;

    @media (max-width: $tablet-breakpoint) {
      margin: 0px;
    }
  }
}

.bold-text {
  font-weight: bold;
}

.support-requests {
  width: 100%;

  &__item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
