.animated-image {
  position: relative;

  &__image {
    position: relative;
    animation: bottom-top-animation 1s infinite;
  }
}

@keyframes bottom-top-animation {
  0% {
    top: 0px;
  }
  50% {
    top: 18px
  }
  100% {
    top: 0px;
  }
}