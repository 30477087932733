@import "../../../styles/breakpoints.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/base.scss";

.game-hud {
  &__header {
    position: fixed;
    width: 100vw;

    top: 0;
    left: 0;
    right: 0;

    border-radius: 0 0 20px 20px;

    font-weight: normal;
    font-size: 14px;

    background: linear-gradient(#ded2f9, #9f82e0);
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5);
    // z-index: 8;
    display: block;

   
    &--shadowed {
      &::after {
        content: "";

        @extend .full-screen-absolute-container;
        
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        @media (min-width: $tablet-breakpoint) {
          display: none;
        }          
      }
    }

    &__currency {
      display: flex;

      flex-direction: row;
      justify-content: flex-end;
      padding: 10px 11px;

      @media (min-width: $tablet-breakpoint) {
        padding: 0 30px 0 0;
      }

      & > div:first-child {
        margin-right: 11px;
      }

      &--upper {
        z-index: 99;
      }

      &--hide {
        visibility: hidden;

        @media screen and (min-width: $tablet-breakpoint) {
          visibility: visible;
        }
      }
    }

    &__indicators {
      position: absolute !important;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      align-items: flex-start;

      width: 100%;
      margin-top: 15px;
      padding: 0 11px;

      &__icon {
        max-height: 20px;
      }

      & > div {
        position: unset !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;

        & > div {
          display: flex;
          flex-direction: row;
          align-items: center;

          border-radius: 3px;
          padding: 0px 7px;
          cursor: pointer;

          background: $gradient-disabled;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

          &:last-child{
            margin-top: 10px;
            width: 80%;
            span{
              font-size: 14px;
            }
            
          }
        }
      }

      &__health {
        display: flex;
        flex-direction: column !important;
        justify-content: flex-start !important;


        width: 63px;
        height: 55px;

        background: $gradient-disabled;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        cursor: pointer;
        
        img{
          width: 50%;
          @keyframes heartbeat
          {
            0%
            {
              transform: scale( .75 );
            }
            25%
            {
              transform: scale( 1 );
            }
            50%
            {
              transform: scale( .75 );
            }
            75%
            {
              transform: scale( 1 );
            }
            100%
            {
              transform: scale( .75 );
            }
          }

          animation: heartbeat 2s infinite;
        }
      }

      &__age {
        padding: 2.5px 10px !important;
        margin-right: 11px;

        img {
          margin-right: 8px;
        }
      }

      @media (min-width: $tablet-breakpoint) {
        justify-content: flex-start;
        position: absolute !important;

        height: fit-content;
        width: fit-content;
        
        left: 0;

        margin: 0;
        margin-left: 29px;

        & > div:nth-child(2) {
          order: -1;
          margin-right: 50px;
        }

        & > div:nth-child(1) {
          margin-right: 20px;
        }
      }
    }

    @media (min-width: $tablet-breakpoint) {
      display: flex;

      flex-direction: row-reverse;

      align-items: center;

      width: 100%;
      top: 80px;

      background: none;
      box-shadow: none;
    }
  }

  &__footer {
    position: fixed;
    box-sizing: border-box;

    width: 100%;
    margin-top: -155px;

    padding: 10px;

    &__main {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &__clients-count {
    margin-left: 5px;
  }

}

.game-hud__footer__main,
.game-hud__footer__zoom,
.game-hud__footer__error,
.game-hud__footer__tenders {
  position: fixed;
  box-sizing: border-box;
}

.game-hud__footer__main {
  width: 100%;
  bottom: 0;
  padding: 11px;
  padding-bottom: 17px;

  & > div {
    display: flex;
  }

  & > div:last-child > button:first-child,
  div:first-child > button:first-child {
    margin-right: 10px;
  }

  @media (min-width: $tablet-breakpoint) {
    justify-content: flex-end;
    padding: 30px;

    & > div {
      margin-left: 10px;
    }

    & > div:nth-child(2) {
      order: 1;
    }
    width: fit-content;
    right: 0;
  }
}

.game-hud__footer__zoom {
  display: flex;
  flex-direction: column;
  bottom: 120px;
  padding-left: 16px;

  & > button {
    margin-top: 10px;
  }

  // @media (min-width: $tablet-breakpoint) {
  //   display: none;
  // }
}

.game-hud__footer__error {
  bottom: 135px;
  right: 0;
  padding-right: 12px;

  @media (min-width: $tablet-breakpoint) {
    bottom: 180px;
    padding-right: 42px;
  }
}

.game-hud__footer__tenders {
  bottom: 75px;
  right: 0;
  padding-right: 10px;

  @media (min-width: $tablet-breakpoint) {
    bottom: 120px;
    padding-right: 40px;
  }
}

.tutorial {
  transform: translate(-50%, -50%);
  z-index: 10;
  position: absolute;
  color: white;

  display: flex;
  justify-content: center;
  flex-direction: column;

  border-radius: 10px;
  padding: 18px;
  background: rgba(0, 0, 0, 0.7);
  width: 246px;

  span {
    font-weight: normal;
    font-size: 14px;
    white-space: pre-wrap;
  }

  &__desktop {
    @extend .tutorial;

    display: none;

    @media (min-width: $tablet-breakpoint) {
      display: flex;
    }
  }

  &__mobile {
    @extend .tutorial;

    @media (min-width: $tablet-breakpoint) {
      display: none;
    }
  }

  button {
    margin-top: 10px;
  }

  @media (min-width: $tablet-breakpoint) {
    left: 20%;
    font-size: 16px;
    width: 250px;
  }
}