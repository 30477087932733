.estimation {
  font-weight: bold;
  img:first-child {
    max-height: 25px;
  }

  img:nth-child(2) {
    margin-right: 3px;
  }

  &--pointer {
    cursor: pointer;
  }
}
