.welcome {
  &__top__wrapper {
    position: relative;
    display: flex;
    width: 100vw;
    min-height: 90vh;
    border-radius: 0px 0px 50px 50px;
    overflow: hidden;

    @media (max-width: 580px) {
      min-height: 75vh;
      border-radius: 0;
    }
  }

  &__top__wrapper-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../../images/promo/game-city-hero-tiny.jpg");
    background-size: cover;
    background-position: top;
    filter: blur(20px);

    &--bg-loaded {
      background-image: url("../../images/promo/game-city-hero.jpg");
      filter: none;
    }
  }

  &__info {
    z-index: 100;
    max-width: 405px;
    border-radius: 20px;
    background: white;
    margin-left: 90px;
    box-shadow: 0px 6px 28px 0px #0000001a;
    padding: 40px 30px;
    box-sizing: border-box;
    align-self: center;

    @media (max-width: 1089px) {
      width: 90vw;
      margin: 0 auto;
    }

    @media (max-width: 580px) {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 4px;
      box-shadow: 0px 9.45396px 47.2698px rgb(0 0 0 / 10%);
    }

    & > p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: left;
      margin: 16px 0px 22px;
    }

    &__buttons {
      width: 100%;
      display: flex;
      flex-direction: row;

      :first-child {
        width: 157px;
        max-width: 157px;
      }

      :last-child {
        margin-left: 8px;
        width: 115px;
      }
    }
  }

  &__buttonWrapper {
    margin-top: 30px;

    button {
      min-width: 120px;
    }
  }
}