.awards {
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  & > div {

    margin-right: 8px;
    margin-bottom: 8px;

    width: calc(100% / 3 - 5.4px);
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }

  &_award {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      position: relative;

      background: radial-gradient(
        48.82% 60.34% at 29.62% 39.66%,
        #ffeb81 0%,
        #ffc839 68.23%
      );

      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      width: 100%;
      height: 75px;

      p {
        margin: 0;
        color: #ffffff;

        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
      }

      img {
        height: 75%;
        z-index: 99;
      }
    }

    button {
      max-width: 100%;
      width: 100%;
      min-width: fit-content;
      margin-top: 3px;
      min-height: 30px;
    }
  }
}
