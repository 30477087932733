.countBadge {
  position: absolute !important;

  top: 0;
  bottom: 0;
  right: 0;
  height: fit-content;
  font-size: 10px;
  width: fit-content;
  background: linear-gradient(180deg, #ba9cff 30.73%, #865fdc 100%);

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 23px;
  color: white;
  padding: 1px 5px;
  border: solid white 2px;
  margin: 2px;
  z-index: 1;

  &--padded {
    top: 8px;
    right: 8px;
    margin: 0;
  }
}
