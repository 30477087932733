$progress-bar-background-color: #B095EA;
$progress-bar-active-bar-color: #ffffff;
$game-grey-default: #E8EEF3;
$game-warning-color: #FDEBC9;
$game-alarm-color: #FDEBC9;

$color-red: #ff4f12;
$color-green: #5c913b;

$game-preloader-percents-text-color: rgba(255, 255, 255, 0.5);
$game-preloader-background: linear-gradient(180deg, #CAB5F9 13.33%, $progress-bar-background-color 100%);
$simple-modal-header-color: linear-gradient(180deg, #DED2F9 13.33%, #9F82E0 100%);

$gradient-disabled: linear-gradient(180deg, #ffffff 30.73%, #e2e2e2 100%);
$gradient-disabled-hover: linear-gradient(180deg, #BCBCBC 0%, #9B9B9B 95%);
$gradient-green-button: linear-gradient(180deg, #77b255 0%, $color-green 95%);
