@import "../../../styles/breakpoints.scss";

.image {
  position: relative;
  margin-top: -58px;
  display: flex;
  flex-direction: column;

  width: 275px;
  height: 171px;

  .chevron {
    & > div {
      z-index: 1;
      margin-top: 29px;
    }
  }
  
  .closeButton {
    position: absolute;
    right: 12px;
    top: 39px;
  
    outline: none;
    border: none;
    background: none;
    z-index: 2;

    &--min-top-offset { // для модалок линии связи
      top: 34px;
    }
  }

  &--min {
    height: 125px;
    margin-top:  -12px;

    .closeButton {
      top: -7px;
    }

    .chevron {
      & > div {
        margin-top: -18px;
      }
    }
  }

  &--line-banner {
    .chevron {
      & > div {
        margin-top: 23px;
      }
    }
  }

  & > embed {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &--big {
    margin-top: -24px;
    height: 210px;

    @media screen and (max-height: $mobile-height-breakpoint) {
      height: 170px;
    }

    .closeButton {
      top: -11px;
    }
  }

  &--bg-gradient {
    background: radial-gradient(48.82% 60.34% at 29.62% 39.66%, #FFEB81 0%, #FFC839 68.23%);
    border-radius: 10px;
  }
}

.headerText {
  position: absolute;
  bottom: 10px;
  left: 10px;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;  
  color: white;
}

.banner-img {
  height: 100%;
  max-width: 100%;
  border-radius: 10px;
  align-self: center;
}
