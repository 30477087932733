.giftSlider {
  margin: 15px 10px;
  width: auto;
  .rc-slider-handle {
    background-color: yellowgreen;
    background-image: url("../../../../images/dailyGifts/dot.png");
    background-position: center;

    width: 18px;
    height: 18px;
    border: 0;


    top: 3px;
  }

  .rc-slider-track {
    background: #a68ce3;
  }

  .rc-slider-mark {
    position: relative;
    top: 0;
    margin-top: -20px;
    box-sizing: border-box;

    width: auto;
    span {
      font-weight: bold;
      font-size: 10px;
      color: black;
    }
  }
}
