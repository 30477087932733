.feedback {
  h4 {
    font-size: 16px;
    line-height: 18px;
    margin: 0;
  }

  p {
    font-size: 14px;
    margin: 10px 0px;
  }

  button {
    font-size: 12px;
    width: 76px;
    height: 30px;
  }
}
