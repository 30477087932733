.info {
  h5 {
    margin: 0 0 9px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
  }
  p {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
  }
}
