@import '../../../../styles/colors.scss';

.progress-bar {
  height: 8px;
  width: 100%;
  border-radius: 17px;
  background-color: $progress-bar-background-color;

  &__value-bar {
    height: 100%;
    border-radius: 17px;
    background-color: $progress-bar-active-bar-color;
    transition: width 0.5s linear;
  }
}