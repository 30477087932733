.notification-view {
    padding: 9px;
    box-sizing: border-box;

    &__header {
        margin-top: 0;
        margin-bottom: 5px;
    }

    &__description {
        white-space: pre-line;
        font-size: 14px;
        line-height: 16px;
        font-weight: normal;
        font-family: "rtk-basis-regular";
    }

    &__accept-button {
        max-width: 100%;
        width: 100%;
        margin-top: 9px;
        font-size: 14px;
        line-height: 14px;
        font-family: "rtk-basis-regular";
    }
}