.body {
  position: relative;
}

.staff {
  -webkit-overflow-scrolling: auto !important;
  position: relative;

  & > div:nth-child(2) {
    padding-top: 30px;
  }

  &__bold-text {
    font-weight: bold;
  }

  &__normal-text {
    font-weight: 400 !important;
  }

  button {
    font-size: 12px;
    padding: 2px;
    img {
      width: 20px;
    }
  }

  p,
  span,
  h4,
  span {
    font-size: 8px;
  }

  span {
    font-weight: 400;
    font-weight: bold;
    font-size: 12px;
  }

  p {
    font-size: 14px;
  }

  b {
    font-size: 14px;
    white-space: pre;
  }

  h3 {
    font-size: 16px;
    text-align: left;
    margin: 0;
  }

  h4 {
    text-align: left;
    margin: 0;
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 12px;
  }

  &__impact {
    line-height: 16px;
    
    span {
      font-size: 14px;
    }
  }

  &__description {
    margin-bottom: 24px;
  }
}

.raiseDepartment {
  display: flex;
  align-items: center;

  & > div {
    max-width: 100%;
  }

  h3 {
    text-align: left;
    margin-right: 10px;
    font-size: 14px;
    line-height: 16px;
  }

  p {
    font-size: 14px;
    margin: 0;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 100%;
    min-width: fit-content;
    width: 120px;
    padding: 3px;
    display: flex;

    & > img {
      width: 15px;
    }
  }

  &__progress{
    width: 100%;
    
  }
}

.grayHeader {
  color: rgba(0, 0, 0, 0.5);;
}
