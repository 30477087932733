.close-modal-button {
  position: relative;
  width: 25px;
  height: 25px;
  max-width: 25px;
  min-width: 25px;
  max-height: 25px;
  min-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: linear-gradient(180deg, #e2e2e2, #ffffff);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5);
  border: 0;
  

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: linear-gradient(to bottom, #ffffff, #e2e2e2);
  }

  &:hover {
    &::before {
      background: linear-gradient(#e2e2e2, #ffffff);
    }
  }

  &__icon {
    max-width: 11px;
    min-width: 11px;
    max-height: 11px;
    min-height: 11px;
  }
}