@import "../../../../styles/colors.scss";

.auth {
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
  max-height: 1010px;
  overflow: hidden;

  h4 {
    line-height: 24px;
    margin: 0;
    font-weight: 700;
    font-size: 18px;
  }

  button {
    width: 100%;
    max-width: unset;
  }

  &__body {
    position: relative;
  }

  &__radio {
    display: flex;
    flex-direction: column;
    margin: 12px 0px;
    font-size: 14px;

    & > :last-child {
      margin-top: 12px;
    }
    
    & > div {
      display: flex;
      flex-direction: row;

      & > :first-child {
        margin-right: 12px;
      }
    }
  }

  &__contentLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__login {
      button {
        width: 128px;
      }
  
      a {
        font-size: 14px;
      }
    }

    &__signUp {
      color: #606060;
      font-size: 12px;
      margin: 20px 0px 12px;
      width: 100%;

      &:before {
        content: "";
        width: 32px;
        height: 1px;
        background-color: #BBBCC0;
      }

      &:after {
        content: "";
        width: 32px;
        height: 1px;
        background-color: #BBBCC0;
      }
    }

    &__error {
      & > button {
        width: 110px;
        margin-right: 10px;
      }
    }
  }

  &__text {
    width: 100%;
    font-size: 14px;
    margin: 4px 0px 8px;
  }

  &__form {
    width: 100%;

    & > :not(:last-child) {
      margin-bottom: 10px;
    }

    input::placeholder {
      color: #979799;
    }

    button {
      height: 40px;
      font-size: 12px;
    }

    &__element{
      &--error{
        border: red 1px solid;
      }
    }
  }

  &__policy {
    width: 100%;
    font-size: 12px;
    line-height: 14px;
    color: #979799;
    margin-top: 15px;
  }

  &__frameWrapper {
    height: 500px;
    overflow: hidden;
    border-radius: 10px;
  }

  iframe {
    width: 100%;
    width: 370px;
    height: 1045px;
    border: none;
    transform: scale(0.7);
    transform-origin: left top;
    overflow: hidden;
    border-radius: 10px;
  }
}

.radioButton {
  cursor: pointer;
  position: relative;
  width: 16px;
  height: 16px;
  max-height: 16px;
  max-width: 16px;
  border-radius: 50px;
  background-image: linear-gradient(180deg, #E2E2E2 0%, #FFFFFF 82.81%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 50px;

  &--active {
    height: 8px;
    width: 8px;
    background-image: linear-gradient(180deg, #77B255 0%, $color-green 95%), 
                      linear-gradient(180deg, #E2E2E2 0%, #FFFFFF 82.81%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: solid 5px transparent;
  }
}

.message {
  font-size: 14px;
  line-height: 16px;
  margin: 10px 0px;
}

.RTSignUpActionSteps{
  h4 {
    font-size: 18px;
    margin: 10px 0;
  }

  p {
    font-size: 14px;
    margin: 0;
  }

  ol {
    margin: 10px 0 10px 20px;
    padding: 0;
    li{
      font-size: 14px;
      line-height: 16px;
    }
  }

  a{
    width: 100%;
  }

  button{
    margin-top: 15px;
    min-width: 100% !important;
    min-height: 40px;

    width: 100%;
    height: 40px;

  }

}

.warning {
  line-height: 16px;
   
  h4 {
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 10px;
  }

  p {
    font-size: 14px;
    margin: 0;
  }

  button {
    font-size: 12px;
    width: 100%;
    height: 30px;
    max-width: unset;
    margin: 10px 0 0;
  }
}
