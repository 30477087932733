.card {
  width: calc(100% / 3);
  padding-right: 7px;

  &:last-child {
    padding-right: 0;
  }

  &:first-child {
    padding-right: 7px;
  }

  button {
    min-width: auto;
    width: 100%;

    margin-top: 5px;
    height: auto;
    padding: 5px;
  }

  &--blocked {
    
    img {
      filter: grayscale(100);
    }

    & > div > div > div {
      background: linear-gradient(180deg, #9e9e9e 0%, #797979 95%) !important;
      opacity: 0.5;
    }
  }
}
