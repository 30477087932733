.full-screen-absolute-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fixed-full-width-bottom-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.disabled-highlight {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
