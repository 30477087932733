.epoch {
  p {
    margin-top: 0;
    font-size: 14px;
  }

  h5 {
    font-size: 16px;
    margin: 0;
    margin-bottom: 5px;
  }

  &__technologies {
    & > div {
      display: inline-block;
      width: fit-content;
      color: #ffffff;
      font-size: 14px;
      padding: 5px 8px;
      background-color: #b6babd;
      border-radius: 20px;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    button {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      max-width: fit-content;
      padding: 2px 10px;
      margin-right: 10px;
      min-width: auto;
    }
  }
}
