@import "../../../styles/colors.scss";
@import "../../../styles/breakpoints.scss";

.navigation {
  position: fixed;
  z-index: 100;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  top: 0;

  background: rgba(243, 233, 196, 0.6);
  backdrop-filter: blur(10px);
  width: 100%;
  height: 56px;

  box-sizing: border-box;
  padding: 0px 12px 0px 32px;

  &__logo {
    width: 121px;
    height: 32px;
    mix-blend-mode: multiply;
  }

  @media (max-width: $tablet-breakpoint) {
    &--hide {
      height: 0px;
      z-index: 8;
    }

    &--hide &__logo {
      display: none;
    }
  }

  &__menu-bars {
    display: none;

    @media (max-width: $tablet-breakpoint) {
      display: block;
    }

    &--gradient {
      position: absolute;
      top: 10px;
      left: 10px;

      background: $gradient-disabled;
      box-shadow: 0px 4.8px 9.6px rgba(0, 0, 0, 0.1);
      width: 32px;
      height: 32px;
      border-radius: 600px;
    }
  }

  &__close-button {
    display: none;
    position: absolute;
    top: 15px;
    right: 15px;

    @media (max-width: $tablet-breakpoint) {
      display: block;

      &--left {
        left: 15px;
        right: auto;
      }
    }
  }
  
  &__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style-type: none;
    height: 100%;
    margin: 0;
    padding: 0;

    @media (max-width: $tablet-breakpoint) {
      display: none;
      position: absolute;
      top: 52px;
      right: 0;
      background: rgba(243, 233, 196, 0.8);
      backdrop-filter: blur(56px);
      height: 335px;
      width: 186px;
      border-radius: 10px;

      &--show {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
      }

      &--left {
        left: 0;
        margin: 0;

        li {
          padding: 0px 20px !important;
        }
      }
    }

    li {
      height: 100%;

      @media (max-width: $tablet-breakpoint) {
        height: auto;
      }
    }
  }
}

.nav-link {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  color: #000000;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  padding: 0px 20px !important;
  margin: 0;
  cursor: pointer;

  &--active {
    border-bottom: solid 4px $color-green;

    @media (max-width: $tablet-breakpoint) {
      border-bottom: none;
    }
  }
}
