.info {
  &__header {
    font-size: 16px;
    line-height: 18px;
    margin: 0;
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    margin: 10px 0 0;
    white-space: pre-wrap;
  }
}
