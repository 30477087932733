@import '../../../styles/colors.scss';

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $game-preloader-background;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__progress-bar {
    margin-top: 40px;
  }

  &__percent-text {
    display: block;
    font-size: 64px;
    font-weight: bold;
    color: $game-preloader-percents-text-color;
    position: relative;
    bottom: -63px;
  }
}