.card {
  display: flex;
  flex-direction: column;

  margin-right: 7px;
  margin-bottom: 7px;

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 2px;
    max-width: 100%;
    margin-top: 5px;
    min-width: fit-content;
    width: 100%;
    min-height: 20px;
    height: fit-content;
  }

  &:first-child {
    margin-right: 7px;
  }

  &:last-child {
    margin-right: 0;
  }

  &__content {
    width: 100%;

    height: 75px;

    font-size: 8px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    &--high {
      background: linear-gradient(180deg, #ffffff 0%, #ff8383 100%);
    }

    &--middle {
      background: linear-gradient(180deg, #ffffff, #bce125 100%);
    }

    &--low {
      background: linear-gradient(180deg, #faf0b7 0%, #e6c620 100%);
    }

    &--absent{
      background: gray;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    position: relative;

    width: 100%;
    align-items: flex-end;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 70%;
    }

    p {
      width: 80%;
      z-index: 1;
      font-size: 12px;
      color: #ffffff;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 5px;
      text-align: center;
      margin: 5px auto !important;
      line-height: 10px;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    }

    &--withSize{
      img{
        width: 40%;
        top: -20%;
      }
    }
  }
}
