@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.prizes {
  &__list {
    display: grid;
    row-gap: 5px;
    margin-top: 23px;
  }

  &__prize {
    display: flex;
    flex-flow: column;
    justify-content: center;

    width: 100%;
    height: fit-content;
    min-height: 100px;

    background: #E8EEF3;

    border-radius: 10px;
    cursor: pointer;

    &__date-text, &__status-text {
      font-weight: bold;
    }

    p {
      margin: 0;
      font-size: 16px;
      line-height: 160%;
  
      @media (max-width: $mobile-breakpoint) {
        font-size: 14px;
      }
    }  

    &__header {
      display: flex;
      flex-flow: row;
      align-items: center;

      min-height: 100px;
      
      box-sizing: border-box;
      padding: 10px;
      padding-right: 30px;
      
      @media (max-width: $mobile-breakpoint) {
        padding-right: 10px;
      }

      &__imageWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-right: 22px;

        @media (max-width: $mobile-breakpoint) {
          margin-right: 10px;
        }

        img {
          max-width: 74px;

          @media (max-width: $mobile-breakpoint) {
            max-width: 60px;
          }
        }
      }

      &__arrow {
        width: 12px;
        height: 9px;
        margin-left: auto;
      }

      &__description {
        display: flex;
        flex-flow: column;

        @media (max-width: $mobile-breakpoint) {
          margin-right: 5px;
        }

        p {
          margin: 0;
        }

        &__header {
          display: flex;
          flex-flow: row wrap;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 5px;

          & > :first-child {
            color: #606060;
            margin-right: 20px;
          }

          & > :last-child {
            color: #B52E00;
          }

          &--active {
            & > :last-child {
              color: $color-green;
            }
          }
        }
      }
    }

    &__dropdown {
      padding: 10px;
      display: none;

      &--active {
        display: block;
      }

      &__code {
        margin: 0px 0px 10px;
        
        span {
          font-weight: bold;
          color: #7700FF;
        }
      }

      &__activate {
        margin-bottom: 23px;

        p {
          font-size: 14px;
          color: #606060;
          margin: 0 0 23px;
        }

        button {
          margin-top: 10px;
          height: 30px;
        }
      }

      &__activation {
        line-height: 0;

        p {
          margin: 0;
          line-height: 160%;
        }

        :not(:last-child) {
          margin-bottom: 5px;
        }
          
        ul {
          display: flex;
          flex-flow: column;
          width: 100%;
          max-width: calc(100% - 24px);
          list-style: url("../../images/svg/list.svg");
          padding-left: 24px;
      
          margin: 0;
          font-size: 16px;
          line-height: 25.6px;
          
          li {
            min-height: 28px;
          }

          :not(:last-child) {
            margin-bottom: 5px;
          }
      
          @media (max-width: $mobile-breakpoint) {
            font-size: 14px;
            line-height: 22.4px;
          }
        }
      }
    }
  }
}
