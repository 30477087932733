@import "../../../../styles/colors.scss";

.headerText {
  // font-family: Rostelecom Basis;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #000000;

  padding: 0;
  margin: 0;

  &-warning {
    color: #b52e00;
  }
}

/*
.info {
  display: flex;
  align-items: center;

  & > p {
    // font-family: Rostelecom Basis;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    width: 160px;
    margin: 0;
  }
}
*/

.timerProcess {
  display: flex;
  vertical-align: middle;
  align-items: center;

  & > div:first-child {
    width: 60%;
  }
  & > div:last-child {
    width: 40%;
    height: 24px;
  }

  h5 {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    color: $color-green;
    margin: 0;

    img{
      margin-left: 5px;
    }
  }
  p {
    margin: 0;
    font-size: 14px;
  }
}

.container {
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    line-height: 16px;
  }

  span {
    font-size: 14px;
  }

  button {
    font-weight: bold;
    font-size: 12px;
  }

  &__load {
    & > div:first-child{
      display: flex;
      justify-content: space-between;
      img{
        width: 16px;
        height: 16px;
      }
    }
    & > div:last-child {
      margin-top: 5px;
    }
  }

  &__estimation {
    position: absolute;

    top: 0;
    right: 0;
    margin-top: 80px;
    margin-right: 25px;

    & > div {
      display: flex;
      align-items: center;
      padding: 0 3px;
      background: $gradient-disabled;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }
  }

  .info-block {
    width: 100%;
    display: flex;
    flex-direction: row;
  
    &__headers {
      margin-right: 12px;
    }
  
    &__values {
      span {
        font-size: 16px;
      }
    }
  
    &__column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      flex-shrink: 1;
  
    }
  }
}

.range {
  display: flex;
  justify-content: space-between;

  span {
    display: flex;

    align-items: center;
    vertical-align: middle;

    font-weight: bold;
    font-size: 14px;
  }
}

.lineButtons {
  align-items: center;
  @extend .range;

  button {
    min-width: 100px;
    width: 100px;
    white-space: nowrap;
  }

  span {
    font-size: 12px;
  }

  &__container {
    display: flex;

    &--full {
      margin-top: 7px;
      width: 100%;
      justify-content: space-between;
      flex-direction: row-reverse;
      flex: 2 0 auto;

      button {
        &:nth-child(1) { flex-grow: 2; }
        &:nth-child(2) { flex-grow: 1; margin-right: 10px; }
      }
    }
  }

  box-sizing: 100%;
}

.city-modal-button {
  min-width: 100px !important;
}

.greenText{
  color: $color-green;
}

.redText {
  color: #B52E00;
}

.crystal-price {
  width: 16px;
  height: 16px;

  margin-bottom: 1px;
  margin-right: 2px;
}