.message-list {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  overflow: auto;

  &__message {
    margin-bottom: 6px;

    &:first-child {
      margin-top: 17px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}