@import "../../../styles/base.scss";

.cookie-alert {
  @extend .fixed-full-width-bottom-container;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 110;
  transition: bottom 1s ease-in-out;

  &--accepted {
    bottom: -500px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: white;
    margin-right: 14px;
    max-width: 920px;
  }

  &__rule-link {
    color: white;
    text-decoration: underline;
  }

  @media screen and(max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 12px;
    padding-bottom: 20px;

    &__text {
      margin-right: 0;
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}