.currency {
  position: relative;
  display: flex;

  cursor: pointer;

  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  width: fit-content;

  font-weight: bold;
  font-size: 14px;
  padding: 6px 8px;
  box-shadow: 0px 4.8px 9.6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(#ffffff, #e2e2e2);
  border-radius: 340px;

  border: none;

  & > img {
    position: absolute;
    left: 0;
    padding-left: 5px;
    width: 30px;
  }

  span {
    margin-left: 20px;
    padding: 0 10px;
  }

  &__plus {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    outline: 0;
    border: 0;
    background: linear-gradient(180deg, #e2e2e2 0%, #ffffff 82.81%);
    border-radius: 25px;

    img {
      width: 11px;
      height: 11px;
    }
  }

  margin-right: 10px;

  &:last-child{
    margin: 0;
  }
}
