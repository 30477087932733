.boosters {
  width: 100%;

  img {
    height: 36px;
    width: auto;
  }

  h4 {
    line-height: 1;
    margin: 0;
  }

  &__body {
    padding-bottom: 11px;
    position: relative;
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    margin: 6px 0px 10px;
  }

  &__list {
    display: grid;
    column-gap: 8px;
    grid-auto-columns: 80px;
    grid-auto-flow: column;
    justify-content: center;

    button {
      height: 30px;
      font-size: 12px;
    }
  }
}