.chevron {
  position: absolute;
  text-align: center;
  display: flex;
  color: white;
  align-items: center;

  margin-top: -10px;

  span {
    position: absolute;
    width: 100%;
  }

  &--normal {
    margin-top: -13px;
    span {
      font-size: 16px !important;
    }
  }

  &--small {
    img{
        width: 20px;
    }
    span {
      font-size: 12px !important;
    }
  }
}
