.warning {
  h4 {
    font-size: 16px;
    line-height: 18px;
    margin: 0;
  }

  p {
    font-size: 14px;
    margin: 10px 0 0;
  }
}
