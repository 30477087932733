.problem {
  h4 {
    font-size: 16px;
    line-height: 18px;
    margin: 0;
  }

  &__text {
    font-size: 14px;
    margin: 10px 0px;
  }

  button {
    font-size: 12px;
    width: fit-content;
    max-width: unset;
    height: 30px;
  }

  &__smallText {
    font-size: 12px;
    color: #A0A0A0;
    margin: 10px 0 0;
  }
}
