.questContainer {

  h4{
    margin: 0;
    font-weight: bold;
  }

  p{
    margin: 5px 0 10px 0;
  }

  &__description {
    display: flex;
    flex-direction: row;

    span {
      font-size: 12px;
    }

    h4 {
      font-size: 16px;
      margin: 2px 0 6px 0;
    }

    button{
        width: 80px;
        min-width: fit-content;
        height: 20px;
    }

    img{
      width: 80px;
      height: 75px;

      margin-right: 10px;
    }
  }

  &__timer{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &__progressbar{
          width: 106px;
          height: 20px;
      }
  }
}

.quest {
  position: relative;
  padding-bottom: 11px;
}