@import '../../../styles/colors.scss';

.background {
  border-radius: 10px;
  box-sizing: border-box;
  
  &:not(:first-child){
    margin-top: 5px;
  }

  & > .background__item:not(:first-child) {
    border-top: 1px solid white;
  }

  & > div:not(.without-padding) {
    padding: 10px;
    box-sizing: border-box;
  }

  &--common {
    background: $game-grey-default;
  }

  &--warning {
    background: $game-warning-color;
  }

  &--alarm {
    background: $game-alarm-color;
  }

  &--br-top {
    border-radius: 10px 10px 0 0;
  }

  &--br-bottom {
    border-radius: 0 0 10px 10px;
  }
}
