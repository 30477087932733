@import "../../../styles/colors.scss";

.selector {
  $s: &;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &--brightness {
    #{$s}__variable {
      img {
        filter: brightness(0) invert(1);;
      }

      &--selected {
        img {
          filter: none;
        }
      }
    }
  }

  &__variable {
    position: relative;
    margin-right: 9px;

    &:last-child {
      margin-right: 0px;
    }

    &--staff {
      margin-right: 0;
    }
  }
}

.selector-item {
  width: 100%;
  height: 60px;
  line-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background: linear-gradient(180deg, #ba9cff 30.73%, #865fdc 100%);
  border-radius: 10px;
  box-sizing: border-box;
  
  span {
    font-size: 10px;
    margin-top: 5px;
    width: 100%;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  }

  &--staff {
    width: 50px;
    height: 50px;
    padding: 3px 3px 1px 3px;

    span {
      margin-top: 1px;
    }
  }

  &--low-text {
    span {
      font-size: 8px;
    }
  }

  img {
    height: 28px;
  }

  &--selected {
    color: black;
    background: $gradient-disabled;

    span {
      text-shadow: none;
    }
  }
}
