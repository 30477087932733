@import "../../../../styles/colors.scss";

.technologies {
  position: relative;
  overflow-x: hidden;
  
  -webkit-overflow-scrolling: auto !important;
  
  &__list-head {
    font-weight: bold;
  }
  
  h4 {
    margin: 0;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    & > div {
      flex: 0 0 50%;
      box-sizing: border-box;
    }
  }
}

.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0;

  padding-top: 8px;

  &:nth-child(2n + 1) {
    padding-right: 4px;
  }

  &:nth-child(2n) {
    padding-left: 4px;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    min-width: 100%;
    max-width: 100%;
    height: fit-content;

    font-size: 12px;
    margin-top: 6px;
    padding: 5px 5px;

    img {
      align-items: center;
      width: 20px;
    }
  }

  &--pointer {
    cursor: pointer;

    button {
      cursor: pointer;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  height: 100%;

  & > img {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: 108px;
    margin-top: -10px;
  }

  &__header {
    border-radius: 3px 3px 0 0;

    h6 {
      text-align: center;

      color: #ffffff;
      font-weight: normal;
      font-size: 14px;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
      padding: 9px 4px;
      line-height: 14px;
      margin: 0;
    }
  }

  &__body {
    padding: 8px;

    p {
      font-size: 14px;
      text-align: left;
      margin: 0;

      &:nth-child(2) {
        margin-top: 8px;
        font-weight: bold;
      }
    }

    &__throughput {
      color: $color-green;
    }
  }

  &--DISCOVERED,
  &--AVAILABLE {
    .card__header {
      background: linear-gradient(180deg, #65e3ff 0%, #28c4e7 95%);
    }
  }

  &--NEED_UP_LEVEL_DEPARTMENT,
  &--NOT_AVAILABLE {
    .card__header {
      background: linear-gradient(180deg, #9e9e9e 0%, #797979 95%);
      opacity: 0.5;
    }
  }
}
