@import "../../../styles/colors.scss";

.toasts {
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  width: 95%;
  margin: 0 auto;
  z-index: 99;

  &__toast {
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      box-sizing: border-box;

      z-index: 99;

      margin: 1px 0;

      width: 100%;
      color: #ffffff;
      font-size: 12px;
      padding: 6px;
      font-weight: bold;
      align-items: center;
      border-radius: 8px;
      text-align: center;

      & > img {
        padding: 0 16px;
      }

      button {
        background: inherit;
        border: 0;
        margin: 0 16px;
      }
    }

    &--error {
      & > div {
        background-color: #f05600;
      }
    }

    &--success {
      & > div {
        background-color: $color-green;
      }
    }

    &:hover{
      & > div {
        filter: brightness(0.9);
      }
  
    }
  }

}
