@import "../../styles/breakpoints.scss";

.game-end-results {
  overflow: visible;

  &__head-wrapper {
    position: relative;
    width: 100%;
    height: 90vh;

    @media screen and (max-height: 800px) {
      height: 720px;
    }
  }

  &__hero-bg {
    position: absolute;
    top: 0;
    bottom: 10vh;
    right: 0;
    left: 0;
    background: linear-gradient(90deg, #614385 0%, #516395 100%);

    @media screen and (max-height: 800px) {
      bottom: 80px;
    }
  }

  &__head-content-wrapper {
    position: relative;
    width: 100vw;
    height: 90vh;
    margin-left: 20%;

    @media screen and (max-height: 800px) {
      min-height: 720px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      margin-left: 0;
    }
  }

  &__blurred-sun {
    position: absolute;
    left: -200px;
    bottom: -300px;

    @media screen and (max-width: $mobile-breakpoint) {
      left: -300px;
    }
  }

  &__hero-img {
    position: absolute;
    bottom: 0;
    width: 1410px;
    height: 653px;

    @media screen and (max-width: $mobile-breakpoint) {
      width: 1000px;
      height: 463px;
      left: -250px;
      bottom: 40px;
    }
  }

  &__head-content {
    position: absolute;
    top: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;

    @media screen and (max-width: $mobile-breakpoint) {
      align-items: center;
      top: 100px;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__head-content-header {
    font-weight: 700;
    font-size: 30px;
    line-height: 31.5px;
    margin-bottom: 14px;
  
    @media screen and (max-width: $mobile-breakpoint) {
      text-align: center;
    }
  }

  &__head-content-subheader {
    font-size: 24px;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 15px;

    @media screen and (max-width: $mobile-breakpoint) {
      text-align: center;
    }
  }

  &__remember-button {
    max-width: none;
    padding: 11px 21px;

    @media screen and (max-width: $mobile-breakpoint) {
      position: absolute;
      bottom: calc(10vh + 30px);
    }
    
    @media screen and (max-height: 800px) {
      bottom: 97px;
    }
  }

  &__content {
    padding-left: 20%;
    padding-bottom: 65px;

    @media screen and (max-width: $mobile-breakpoint) {
      padding-left: 22px;
      padding-right: 22px;
      padding-bottom: 120px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__content-header {
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    margin-bottom: 14px;
  }

  &__result-item {
    margin-bottom: 23px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__results {
    margin-bottom: 22px;
  }

  &__prize-item {
    width: 478px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }

  &__prizes {
    margin-bottom: 10px;
  }

  &__all-gifts-button {
    padding: 11px 29px;
    max-width: none;
  }

  &__form-container {
    width: 478px;

    @media screen and (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }

  &__form-label {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-top: 40px;
    margin-bottom: 37px;

    @media screen and (max-width: $mobile-breakpoint) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

.result-item {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 22px;
  }
}