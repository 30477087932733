.employee-view {
  display: flex;    
  position: relative;
  height: 75px;
  width: 80px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  &__avatar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }

  &__name {
    font-size: 12px;
    font-weight: normal;
    width: 100%;
    text-align: center;
  }
}