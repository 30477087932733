@import "../../../styles/colors.scss";

.staffList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  font-weight: bold;
  font-size: 8px;

  flex-wrap: wrap;

  margin: 8px 0 12px 0;

  &__person {
    width: 100%;
    max-width: calc((100% / 3) - 6px);

    padding-right: 8px;

    &:nth-child(3n+3){
      padding-right: 0;
    }

    &:nth-child(n+4){
      margin-top: 20px;
    }

    &__timer{
      margin-top: 9px;
    }


    &--pointer {
      cursor: pointer;

      button {
        cursor: pointer;
      }
    }

    button {
      display: flex;

      justify-content: center;
      align-items: center;

      max-width: 100%;
      min-width: fit-content;
      width: 100%;

      height: fit-content;
      height: fit-content;

      margin-top: 10px;
      padding: 3px;

      & > img {
        width: 15px;
      }
    }

    &__card {
      display: flex;
      
      position: relative;

      height: fit-content;
      height: 75px;
      width: 100%;

      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 3px;

      &__personName {
        font-size: 12px;
        display: inline-block;
        width: 100%;
        text-align: center;
      }

      & > img {
        position: absolute;

        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        margin: auto;
      }

      &__newEmployee{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        text-align: center;
        height: fit-content;
        margin: auto;

        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
      }

    }
    p {
      margin: 11px 0 0 0;
      text-align: center;
      color: $color-green;
      font-size: 10px;
    }
  }
}
