.simple-prize {
  display: flex;
  align-items: center;
  background: #E8EEF3;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;

  &__icon-wrapper {
    width: 74px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 22px;
    flex-shrink: 0;
  }

  &__icon {
    width: 100%;
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }

}