@font-face {
  font-family: "rtk-basis";
  src: url("../fonts/BasisGrotesquePro-Light-Web.woff2");

  font-weight: lighter;
}

@font-face {
  font-family: "rtk-basis";
  src: url("../fonts/BasisGrotesquePro-Regular-Web.woff2");

  font-weight: normal;
}

@font-face {
  font-family: "rtk-basis";
  src: url("../fonts/BasisGrotesquePro-Medium-Web.woff2");

  font-weight: bolder;
}

@font-face {
  font-family: "rtk-basis";
  src: url("../fonts/RostelecomBasis-Bold.otf");

  font-weight: bold;
}


@font-face {
  font-family: "rtk-basis-regular";
  src: url("../fonts/BasisGrotesquePro-Regular-Web.woff2");

  font-weight: normal;
}