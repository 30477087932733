.error {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #77B255;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    left: 0px;
    max-width: 66vw;
    top: 50%;
    bottom: 50%;
    transform: translate(0%, -50%);

    @media (max-width: 1089px) {
      top: 57px;
      margin-left: -15vw;
      max-width: 130vw;
      transform: none;
      bottom: auto;
    }
  }

  &__message {
    max-width: 405px;
    border-radius: 20px;
    background: white;
    box-shadow: 0px 6px 28px 0px #0000001a;
    padding: 40px 30px;
    box-sizing: border-box;
    align-self: center;
    margin-left: 50%;
    z-index: 1;

    @media (max-width: 1089px) {
      margin: 0 auto -150px;
      width: 90vw;
    }

    h1 {
      font-weight: bold;
      font-size: 40px;
      line-height: 105%;
      color: #191D21;
      margin: 0;
    }

    p {
      font-size: 16px;
      line-height: 21px;
      margin: 5px 0px 11px;
      white-space: pre-wrap;
    }

    button {
      height: 40px;
      font-size: 12px;
    }
  }
}
